/* eslint-disable no-console */
export interface MapperInfo {
  id: number;
  name?: string;
  dataLayerPath?: string;
  deprecated?: boolean;
  isAutoCollect?: boolean;
  dataType?: 'string' | 'number' | 'boolean' | 'object';
}
// GTM read the data from datalayer
// our apps send the data to custom object
export const dataLayerMap: MapperInfo[] = [
  {
    id: 1,
    name: 'page_type',
    dataLayerPath: 'p.t',
    dataType: 'string',
  },
  {
    id: 2,
    name: 'L1_category_ID',
    dataLayerPath: 'c.l1.id',
    dataType: 'number',
  },
  {
    id: 3,
    name: 'L2_category_ID',
    dataLayerPath: 'c.l2.id',
    dataType: 'number',
  },
  {
    // L3 Category ID
    id: 4,
    deprecated: true,
    dataLayerPath: 'c.l3.id',
    dataType: 'number',
  },
  {
    //L4 Category ID
    id: 5,
    deprecated: true,
    dataLayerPath: 'c.l4.id',
  },
  {
    // L1 Location ID
    id: 6,
    deprecated: true,
    dataLayerPath: 'l.l1.id',
  },
  {
    id: 7,
    name: 'L2_location_ID',
    dataLayerPath: 'l.l2.id',
    dataType: 'number',
  },
  {
    // L3 Location ID
    id: 8,
    deprecated: true,
    dataLayerPath: 'l.l3.id',
  },
  {
    // L4 Location ID
    id: 9,
    deprecated: true,
    dataLayerPath: 'l.l4.id',
  },
  {
    id: 10,
    name: 'selected_category_ID',
    dataLayerPath: 'c.c.id',
    dataType: 'number',
  },
  {
    id: 11,
    name: 'selected_category_name',
    dataLayerPath: 'c.c.n',
    dataType: 'string',
  },
  {
    id: 12,
    name: 'selected_location_ID',
    dataLayerPath: 'l.c.id',
    dataType: 'number',
  },
  {
    id: 13,
    deprecated: true,
    dataLayerPath: 'l.c.n',
  },
  {
    id: 14,
    name: 'platform_version',
    dataLayerPath: 'p.pl_v',
  },
  {
    id: 15,
    name: 'selected_language',
    dataLayerPath: 'p.lng',
    dataType: 'string',
  },
  {
    id: 16,
    name: 'virtual_url',
    dataLayerPath: 'p.vurl',
  },
  {
    id: 17,
    deprecated: true,
    dataLayerPath: 'p.cfgStr',
  },
  {
    id: 18,
    deprecated: true,
  },
  {
    id: 19,
    name: 'login_provider',
    dataLayerPath: 'u.lip',
  },
  {
    id: 20,
    name: 'hashed_user_ID',
    dataLayerPath: 'u.huid',
  },
  {
    id: 21,
    name: 'hashed_user_email',
    dataLayerPath: 'u.hue',
  },
  {
    id: 22,
    deprecated: true,
    dataLayerPath: 'u.haid',
  },
  {
    id: 23,
    name: 'logged_in',
    dataLayerPath: 'u.li',
  },
  {
    id: 24,
    name: 'user_account_type',
    dataLayerPath: 'u.at',
  },
  {
    id: 25,
    name: 'session_level_test_group',
    dataLayerPath: 'u.tg.stg',
  },
  {
    id: 26,
    deprecated: true,
  },
  {
    // Seller Segment
    id: 27,
    name: 'seller_segment',
    dataLayerPath: 'u.ss',
  },
  {
    // Page-Level Test Group
    id: 28,
    dataLayerPath: 'u.tg.ptg',
  },
  {
    // Hashed Contact Email
    id: 29,
    deprecated: true,
  },
  {
    id: 30,
    name: 'ad_ID',
    dataLayerPath: 'a.id',
  },
  {
    id: 31,
    name: 'ad_price',
    dataLayerPath: 'a.prc.amt',
  },
  {
    id: 32,
    name: 'ad_price_type',
    dataLayerPath: 'a.prc.t',
  },
  {
    id: 33,
    name: 'ad_image_count',
    dataLayerPath: 'a.ic',
  },
  {
    id: 34,
    name: 'ad_type',
    dataLayerPath: 'a.t',
  },
  {
    // Ad Last Published Date
    id: 35,
    dataLayerPath: 'a.lpdt',
  },
  {
    // Ad Creation Date
    id: 36,
    name: 'ad_creation_date',
    dataLayerPath: 'a.cdt',
  },
  {
    deprecated: true,
    id: 37,
    dataLayerPath: 'm.rt',
  },
  {
    id: 38,
    name: 'ad_description_length',
    dataLayerPath: 'a.dl',
  },
  {
    id: 39,
    name: 'seller_account_type',
    dataLayerPath: 'a.u.at',
  },
  {
    id: 40,
    name: 'onsite_search_keyword',
    dataLayerPath: 's.kw',
  },
  {
    id: 41,
    name: 'onsite_search_results_page_number',
    dataLayerPath: 's.pn',
  },
  {
    id: 42,
    name: 'onsite_search_results_page_size',
    dataLayerPath: 's.ps',
  },
  {
    id: 43,
    name: 'onsite_search_total_results',
    dataLayerPath: 's.tr',
  },
  {
    id: 44,
    name: 'onsite_search_distance',
    dataLayerPath: 'l.d',
  },
  {
    id: 45,
    name: 'postal_code',
    dataLayerPath: 'l.pcid',
  },
  {
    id: 46,
    dataLayerPath: 'l.ltlng',
  },
  {
    id: 47,
    name: 'onsite_search_results_sort_type',
    dataLayerPath: 's.srt.t',
  },
  {
    id: 48,
    name: 'onsite_search_results_view_type',
    dataLayerPath: 'p.vt',
  },
  {
    id: 49,
    deprecated: true,
    dataLayerPath: 'a.acs',
  },
  {
    id: 50,
    name: 'click_source_domain',
    isAutoCollect: true,
  },
  {
    // Click Source Path
    id: 51,
  },
  {
    //Click Source Parameters
    id: 52,
  },
  {
    // Click Campaign Parameters
    id: 53,
    deprecated: true,
  },
  {
    id: 54,
    dataLayerPath: 'a.attr.licensePlate',
    name: 'license_plate',
  },
  {
    id: 55,
    deprecated: true,
  },
  {
    id: 56,
    deprecated: true,
  },
  {
    id: 57,
    deprecated: true,
  },
  {
    id: 58,
    deprecated: true,
  },
  {
    id: 59,
    deprecated: true,
  },
  {
    // Device
    id: 60,
  },
  {
    // os
    id: 61,
  },
  {
    // camera
    id: 62,
    deprecated: true,
  },
  {
    //gps
    id: 63,
    deprecated: true,
  },
  {
    // Connection Type
    id: 64,
  },
  {
    // Window Orientation
    id: 65,
  },
  {
    // Window Width
    id: 66,
    deprecated: true,
    dataLayerPath: 's.ssid',
  },
  {
    //Window Height
    id: 67,
    deprecated: true,
  },
  {
    // User Agent
    id: 68,
    dataLayerPath: 'd.ua',
  },
  {
    id: 69,
    name: 'user_permanent_cookie',
    dataLayerPath: 'd.ck',
  },
  {
    // Responsive Layout Type
    id: 70,
    deprecated: true,
    dataLayerPath: 'p.ly',
  },
  {
    id: 71,
    name: 'user_session_cookie',
    dataLayerPath: 'd.s_ck',
  },
  {
    deprecated: true,
    id: 72,
  },
  {
    id: 73,
    deprecated: true,
  },
  {
    id: 74,
    dataLayerPath: 'd.gtm',
  },
  {
    id: 75,
  },
  {
    id: 76,
  },
  {
    id: 77,
    deprecated: true,
  },
  {
    id: 78,
    deprecated: true,
  },
  {
    id: 79,
    deprecated: true,
  },
  {
    // Banner Placements
    id: 80,
    deprecated: true,
  },
  {
    // AdSense (AFS) Placements
    id: 81,
    deprecated: true,
  },
  {
    // AdSense (AFC) Placements
    id: 82,
    deprecated: true,
  },
  {
    // Top Ad IDs 1
    id: 83,
    deprecated: true,
  },
  {
    // Top Ad IDs 2
    id: 84,
    deprecated: true,
  },
  {
    // Advertising Click Time
    id: 85,
    deprecated: true,
  },
  {
    id: 86,
    name: 'order_id',
    dataLayerPath: 'o.id',
  },
  {
    // Internal Campaign Name
    id: 87,
  },
  {
    id: 88,
    name: 'paymentProviderType',
    dataLayerPath: 'o.py.pp',
  },
  {
    id: 89,
    deprecated: true,
  },
  {
    id: 90,
    name: 'L1_category_name',
    dataLayerPath: 'c.l1.n',
  },
  {
    id: 91,
    name: 'L2_category_name',
    dataLayerPath: 'c.l2.n',
  },
  {
    // L3 Category Name
    id: 92,
    deprecated: true,
  },
  {
    // L4 Category Name
    id: 93,
    deprecated: true,
  },
  {
    // L1 Location Name
    id: 94,
    deprecated: true,
  },
  {
    // L2 Location Name
    id: 95,
    deprecated: true,
  },
  {
    // L3 Location Name
    id: 96,
    deprecated: true,
  },
  {
    // L4 Location Name
    id: 97,
    deprecated: true,
  },
  {
    // Onsite Search Location Name
    id: 98,
    deprecated: true,
  },
  {
    id: 99,
    deprecated: true,
  },
  {
    id: 100,
    name: 'onsite_search_min_price',
    dataLayerPath: 's.prc.mn',
  },
  {
    id: 101,
    name: 'onsite_search_max_price',
    dataLayerPath: 's.prc.mx',
  },
  {
    id: 102,
    deprecated: true,
  },
  {
    id: 103,
    name: 'ad_seller_type',
    dataLayerPath: 'a.u.adSellerType',
  },
  {
    id: 104,
    name: 'attribute_count',
    dataLayerPath: 's.attrCount',
  },
  {
    id: 105,
    deprecated: true,
    dataLayerPath: 's.t',
  },
  {
    id: 106,
    deprecated: true,
  },
  {
    id: 107,
    name: 'ad_contact_options',
    dataLayerPath: 'a.attr.cntcts',
  },
  {
    id: 108,
    name: 'ad_attributes',
    dataLayerPath: 'a.attr.value',
  },
  {
    id: 109,
    name: 'search_attributes',
    dataLayerPath: 's.attr',
  },
  {
    id: 110,
    name: 'ad_attributes_shipping_partner',
    dataLayerPath: 'a.attr.shippingPartner',
  },
  {
    id: 111,
    name: 'ad_attributes_shipping_method',
    dataLayerPath: 'a.attr.shippingMethod',
  },
  {
    id: 112,
    deprecated: true,
    dataLayerPath: 's.cid',
  },
  {
    id: 113,
    deprecated: true,
  },
  {
    id: 114,
    deprecated: true,
  },
  {
    id: 115,
    name: 'shipping_price',
    dataLayerPath: 'o.shippingPrice',
  },
  {
    id: 116,
    deprecated: true,
    dataLayerPath: 'a.attr.sellerSegment',
  },
  {
    id: 117,
    deprecated: true,
  },
  {
    id: 118,
  },
  {
    id: 119,
    name: 'category_id',
  },
  {
    id: 120,
    name: 'seller_id',
  },
  {
    id: 121,
    name: 'seller_name',
  },
  {
    // cd122_Outlet filter is selected
    id: 122,
  },
  {
    id: 123,
    name: 'activeFeatures',
    dataLayerPath: 'a.feats',
  },
  {
    // cd124_Lucky Number
    id: 124,
  },
  {
    id: 125,
    name: 'lastAdRepliedTime',
    dataLayerPath: 'u.lar',
  },
  {
    id: 126,
    dataLayerPath: 'u.lap',
  },
  {
    id: 127,
    name: 'dealerPackage',
    dataLayerPath: 'a.u.dp',
  },
  {
    id: 128,
    name: 'kyc_onboarding_entry',
    dataLayerPath: 'c.kycOnboardingEntry',
  },
  {
    id: 129,
  },
  {
    id: 130,
    deprecated: true,
  },
  {
    id: 131,
    deprecated: true,
  },
  {
    // 132 - User label 1
    id: 132,
    dataLayerPath: 'userLabels.1',
  },
  {
    id: 133,
    dataLayerPath: 'userLabels.2',
  },
  {
    id: 134,
    deprecated: true,
  },
  {
    deprecated: true,
    id: 135,
    dataLayerPath: 'a.not.id',
  },
  {
    id: 136,
  },
  {
    id: 137,
  },
  {
    id: 138,
  },
  {
    id: 139,
  },
  {
    id: 140,
  },
  {
    id: 141,
    deprecated: true,
  },
  {
    id: 142,
    deprecated: true,
    dataLayerPath: 'a.u.scr',
  },
  {
    id: 143,
    deprecated: true,
    dataLayerPath: 'a.u.rc',
  },
  {
    id: 144,
    name: 'message_conversation_ID',
    dataLayerPath: 'm.cid',
  },
  {
    id: 145,
    deprecated: true,
    dataLayerPath: 'p.ppt',
  },
  {
    id: 146,
    name: 'hashed_seller_ID',
    dataLayerPath: 'a.u.huid',
  },
  {
    id: 147,
    dataLayerPath: 'clickInfoStr',
  },
  {
    id: 148,
    deprecated: true,
    dataLayerPath: 's.ssid',
  },
  {
    id: 149,
    deprecated: true,
  },
  {
    id: 150,
    deprecated: true,
  },
  {
    id: 151,
    deprecated: true,
  },
  {
    id: 152,
    deprecated: true,
  },
  {
    id: 153,
    deprecated: true,
  },
  {
    id: 154,
    deprecated: true,
  },
  {
    id: 155,
    name: 'paymentIncludesBuyerProtection',
    dataLayerPath: 'o.paymentIncludesBuyerProtection',
  },
  {
    id: 156,
    deprecated: true,
  },
  {
    id: 157,
    name: 'shipping_tier',
    dataLayerPath: 'o.shippingTier',
  },
  {
    id: 158,
    deprecated: true,
  },
  {
    id: 159,
    deprecated: true,
  },
  {
    id: 160,
    name: 'payment_method',
    dataLayerPath: 'o.paymentMethod',
  },
  {
    id: 161,
    deprecated: true,
  },
  {
    id: 162,
    deprecated: true,
  },
  {
    id: 163,
    deprecated: true,
  },
  {
    id: 164,
    deprecated: true,
  },
  {
    id: 165,
    deprecated: true,
  },
  {
    id: 166,
    deprecated: true,
  },
  {
    id: 167,
    name: 'DomainCategory',
    dataLayerPath: 's.dc',
  },
  {
    id: 168,
    deprecated: true,
  },
  {
    id: 169,
    deprecated: true,
  },
  {
    id: 170,
    deprecated: true,
  },
  {
    id: 171,
    deprecated: true,
  },
  {
    id: 172,
    deprecated: true,
  },
  {
    id: 173,
    deprecated: true,
  },
  {
    id: 174,
  },
  {
    id: 175,
  },
  {
    id: 176,
  },
  {
    id: 177,
  },
  {
    id: 178,
    deprecated: true,
  },
  {
    id: 179,
    deprecated: true,
  },
  {
    id: 180,
    name: 'buyerProtection',
    dataLayerPath: 'a.buyerProtectionStatus',
  },
  {
    id: 181,
    deprecated: true,
  },
  {
    id: 182,
    deprecated: true,
  },
  {
    id: 183,
    deprecated: true,
  },
  {
    id: 184,
    name: 'shipping_cost',
    dataLayerPath: 'a.shipping_cost',
  },
  {
    id: 185,
    name: 'BIN_Status',
    dataLayerPath: 'a.binStatus',
  },
  {
    id: 186,
    deprecated: true,
  },
  {
    id: 187,
    deprecated: true,
  },
  {
    id: 188,
    name: 'gdprConsent',
    dataLayerPath: 'gdprConsent',
  },
  {
    id: 189,
    deprecated: true,
  },
  {
    id: 190,
    deprecated: true,
  },
  {
    id: 191,
    deprecated: true,
  },
  {
    id: 192,
    deprecated: true,
  },
  {
    id: 193,
    deprecated: true,
  },
  {
    id: 194,
    deprecated: true,
  },
  {
    id: 195,
    deprecated: true,
  },
  {
    id: 196,
    deprecated: true,
  },
  {
    id: 197,
    deprecated: true,
  },
  {
    id: 198,
  },
  {
    id: 199,
    deprecated: true,
  },
];

export function getDataLayerItemFromDimensionId(dimensionId: number) {
  // eslint-disable-next-line no-magic-numbers
  if (dimensionId >= 200) {
    // ignore this ids as they are for only for testing now
    return;
  }

  const item = dataLayerMap.find((d) => d.id === dimensionId);
  if (!item) {
    console.error(`Dimension ${dimensionId} not found in dataLayerMap`);
    return;
  }
  if (item.isAutoCollect) {
    // ignore as this is auto collect on Tag manager
    return;
  }
  if (item.dataLayerPath === undefined) {
    console.error(`Dimension ${dimensionId} is missing dataLayerPath in dataLayerMap`);
    return;
  }
  return item;
}

export function getDataLayerPathByName(name: string) {
  const item = dataLayerMap.find((d) => d.name === name);
  if (!item) {
    console.error(`item ${name} not found in dataLayerMap`);
    return;
  }
  if (item.dataLayerPath === undefined) {
    console.error(`item ${name} is missing dataLayerPath in dataLayerMap`);
    return;
  }
  return item;
}

const deprecatedDimensionIds = dataLayerMap.filter((d) => d.deprecated).map((d) => d.id);
export function isDeprecatedDimension(id: number) {
  const isDeprecated = deprecatedDimensionIds.includes(id);
  if (isDeprecated) {
    // eslint-disable-next-line no-console
    console.warn(`skipping dimension ${id} as it is deprecated, you can safely remove it.`);
  }
  return isDeprecated;
}
